module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Realise","short_name":"Realise","start_url":"/","background_color":"#009999;","theme_color":"#009999;","display":"minimal-ui","icon":"/github/workspace/gatsby-swa/src/assets/favicons/favicon.png","icons":[{"src":"/github/workspace/gatsby-swa/src/assets/favicons/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"/github/workspace/gatsby-swa/src/assets/favicons/mstile-150x150.png","sizes":"150x150","type":"image/png"},{"src":"/github/workspace/gatsby-swa/src/assets/favicons/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"/github/workspace/gatsby-swa/src/assets/favicons/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"/github/workspace/gatsby-swa/src/assets/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/github/workspace/gatsby-swa/src/assets/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3d43a44fa20beff6825bce847923729a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
